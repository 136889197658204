$(document).ready(function () {
    var statusCont = $('.statusContainer');
    var stContPosTop = statusCont.offset().top;

    $(window).on( 'scroll', function(){
        var top = $(window).scrollTop();

        if(stContPosTop <= top){
            statusCont.css('position', 'fixed').css('top', '0');
        }else{
            statusCont.css('position', 'absolute').css('top', '70px');
        }
    });
});