function goTo(id) {
    var anchorElem = $('#anchor-'+id);

    $('html, body').animate({
        scrollTop: anchorElem.offset().top
    }, 1000, 'swing');
}

$(document).ready(function () {
    var elements = $('.frm-body .frm-group');
    var arr = [[]];

    function setElemPosition() {
        var x = 0;
        elements.each(function (index) {
            if(index !== elements.length - 1) {
                var nextElem = elements.eq(index + 1);
                var nextOffTop = nextElem.offset().top;
            }else{
                var nextElem = false;
                var nextOffTop = false;
            }

            var offTop = $(this).offset().top;

            if(x === 0){
                if(nextOffTop !== false){
                    arr[x++] = [0, nextOffTop, $(this).attr('data-id')];
                }else{
                    arr[x++] = [0, offTop + 400, $(this).attr('data-id')];
                }
            }else{
                if(nextOffTop !== false){
                    arr[x++] = [offTop, nextOffTop, $(this).attr('data-id')];
                }else{
                    arr[x++] = [offTop, offTop + 400, $(this).attr('data-id')];
                }
            }
        });
    }

    $(window).on( 'scroll', function(){
        var top = $(window).scrollTop();

        arr.forEach(function (k) {
            if(k[0] - 20 < top && k[1] > top + 20){
                $('#sideMenuItems li').each(function () {
                    $(this).removeClass('active');
                });
                $('#menuItem-'+k[2]).addClass('active');
                return false;
            }
        });
    });

    $('.frm-body').on("domChanged", function () {
         setElemPosition();
    });

    setElemPosition();
});