$(document).ready(function () {
    var tabBtn = $('.tab-btn');
    var tabCont = $('.tab-content');

    tabBtn.on('click', function (e) {
        e.preventDefault();

        tabBtn.each(function () {
            $(this).removeClass('active');
            $(this).parent().removeClass('active');
        });

        $(this).addClass('active');
        $(this).parent().addClass('active');

        var target = $(this).attr('target');

        tabCont.find('> div').each(function () {
            $(this).removeClass('show').removeClass('active');
        });

        $(target).addClass('show').addClass('active');

        $(window).scrollTop(0);
    })
});

// $(document).ready(function () {
//     var tabBtn = $('.tab-btn-static');
//     // var tabCont = $('.tab-content');
//
//     tabBtn.on('click', function (e) {
//         // e.preventDefault();
//
//         tabBtn.each(function () {
//             $(this).removeClass('active');
//             $(this).parent().removeClass('active');
//         });
//
//         $(this).addClass('active');
//         $(this).parent().addClass('active');
//
//         // var target = $(this).attr('target');
//         //
//         // tabCont.find('> div').each(function () {
//         //     $(this).removeClass('show').removeClass('active');
//         // });
//         //
//         // $(target).addClass('show').addClass('active');
//
//         // $(window).scrollTop(0);
//     })
// });