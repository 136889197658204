$(document).ready(function () {
    var conf = $('.confirmDelete');
    var actButt = $('.td-button');

    conf.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('data-url');
        var mess = $(this).attr('data-message');

        if(confirm('' + mess + '')){
            window.location.href = '' + url + '';
        }
    });

    actButt.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('data-url');

        window.location.href = '' + url + '';
    });
});