$(document).ready(function () {
    $(".chosen-select").chosen({
        disable_search_threshold: 10,
        no_results_text: "Nebyla nalezena zadna data"
    });
    // $('.chosen-select').on('change:selected', function(evt, params) {
    //
    //     console.log(params);
    //     console.log(evt);
    //
    // });
});
