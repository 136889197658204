// $(document).ready(function () {
//     var btnLoader = $('[loader=true], .loader');
//
//     btnLoader.on('click', function () {
//         addLoader();
//         setTimeout(function () {
//             hideLoader();
//         }, 20000);
//     });
// });
//
// function addLoader(){
//     var body = $('body');
//     var htmlLoader = '<div class="loader-container" style="display: block;"><div class="loader"></div><div class="loader-text">Vyčkejte prosím, připravujeme Váš formulář.</div></div>';
//
//     body.append(htmlLoader);
// }
//
// function hideLoader(){
//     let loader = $('body').find('.loader-container');
//     loader.remove();
// }