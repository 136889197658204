$("form[ajax=true]").submit(function(e){
    var formURL = $(this).attr('ajax-url');
    var formData = new FormData(this);
    $.ajax({
        url: formURL,
        type: 'POST',
        data:  formData,
        mimeType:"multipart/form-data",
        contentType: false,
        cache: false,
        processData:false,
        success: function(data, textStatus, jqXHR)
        {
            $('.statusContainer').css('opacity', '1');
            $('.statusCont .statusBar').css('width', '0%');
            $('.statusContainer .message').html('Ukládání');
            $('.statusCont .statusBar').animate({
                width: '100%'
            }, 1800, function() {
                $('.statusContainer .message').html('Uloženo');
            });

            var jsonData = JSON.parse(JSON.parse(data));

            if(jsonData.length > 0){
                var len = jsonData.length;

                for(var x = 0; x < len; x++){
                    var Xold = jsonData[x]['old'];
                    var Xnew = jsonData[x]['new'];

                    var oldElem = $('#'+Xold);

                    if(oldElem.attr('type') === "radio"){
                        oldElem.val(Xnew);
                        oldElem.parent().parent().find('.remove').remove();
                        oldElem.parent().parent().prepend('<div class="remove"><button class="removeBtn" data-id="'+Xnew+'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div>');
                        oldElem.parent().parent().find('input[type=hidden]').each(function () {
                            $(this).remove();
                        });
                    }else{
                        oldElem.val(Xnew);
                        oldElem.parent().parent().find('.remove').remove();
                        oldElem.parent().parent().prepend('<div class="remove"><button class="removeBtn" data-id="'+Xnew+'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div>');
                        oldElem.parent().parent().find('input[type=hidden]').each(function () {
                            $(this).remove();
                        });
                    }

                }
            }

        },
        error: function(jqXHR, textStatus, errorThrown)
        {
        }
    });
    e.preventDefault();
    e.unbind();
});

$("form[ajax=autosave]").submit(function(e){
    var formURL = $(this).attr('ajax-url');
    var formData = new FormData(this);
    $.ajax({
        url: formURL,
        type: 'POST',
        data:  formData,
        mimeType:"multipart/form-data",
        contentType: false,
        cache: false,
        processData:false,
        success: function(data, textStatus, jqXHR)
        {
            $('.statusContainer').css('opacity', '1');
            $('.statusCont .statusBar').css('width', '0%');
            $('.statusContainer .message').html('Ukládání');
            $('.statusCont .statusBar').animate({
                width: '100%'
            }, 1800, function() {
                $('.statusContainer .message').html('Uloženo');
            });
        },
        error: function(jqXHR, textStatus, errorThrown)
        {
        }
    });
    e.preventDefault();
    e.unbind();
});