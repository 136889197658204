function addFlash(type, mess) {
    var message;

    switch (type){
        case 'error':
            message = '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                '<strong>' + mess + '</strong>\n' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '<span aria-hidden="true">×</span>\n' +
                '</button>\n' +
                '</div>';
            break;
        case 'warning':
            message = '<div class="alert alert-warning alert-dismissible fade show" role="alert">\n' +
                '<strong>' + mess + '</strong>\n' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '<span aria-hidden="true">×</span>\n' +
                '</button>\n' +
                '</div>';
            break;
        case 'info':
            message = '<div class="alert alert-info alert-dismissible fade show" role="alert">\n' +
                '<strong>' + mess + '</strong>\n' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '<span aria-hidden="true">×</span>\n' +
                '</button>\n' +
                '</div>';
            break;
        case 'success':
        default:
            message = '<div class="alert alert-success alert-dismissible fade show" role="alert">\n' +
                '<strong>' + mess + '</strong>\n' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '<span aria-hidden="true">×</span>\n' +
                '</button>\n' +
                '</div>';
            break;
    }
    $('.flash-message').append(message);
}